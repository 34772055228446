<template>
  <el-dialog :visible.sync="showVisible" destroy-on-close :title="title" width="30%" @close="handleClose">
    <div class="w-full p-2">
      <el-input v-model="keyword" placeholder="请输入昵称、用户名或备注搜索"/>
    </div>
    <div class="max-h-600px overflow-y-auto flex flex-col w-full">
      <div class="w-full flex items-center p-4 bg-white hover:bg-blue-300" v-for="friend in visibleFriends" :key="friend.id">
        <el-radio v-if="!multiple" v-model="singleValue" :label="friend.id"></el-radio>
        <template v-if="multiple">
          <span v-if="multiplePicked.includes(friend.id)" @click="clickMulti(friend.id)">✅</span>
          <span v-else @click="clickMulti(friend.id)">⬜</span>
        </template>
        <head-image class="pl-4" :size="45" :name="friend.nickName" :url="friend.headImage" :online="friend.online">
        </head-image>
        <div class="flex-1 pl-2 text-left">
         <div class="w-full text-purple-900"> {{ friend.friendRemark || friend.nickName }}</div>
        </div>
      </div>
      <div slot="footer" class="w-full flex justify-end p-4 border-t-2 border-gray-400">
        <el-button size="small" type="primary" @click="submit">确定</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import HeadImage from '@/components/common/HeadImage.vue'

let rsv
let rej
export default {
  name: 'FriendPicker',
  components: { HeadImage },
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '好友选择'
    },
    disabledFunc: {
      type: Function,
      default: () => false
    },
    visibleFunc: {
      type: Function,
      default: () => true
    },
  },
  data () {
    return {
      keyword: '',
      showVisible: false,
      multiplePicked: [],
      singleValue: null
    }
  },
  methods: {
    clickMulti (id) {
      if (this.multiplePicked.includes(id)) {
        this.multiplePicked = this.multiplePicked.filter(m => m !== id)
      } else {
        this.multiplePicked.push(id)
      }
    },
    submit () {
      if (this.multiple) {
        rsv(this.multiplePicked)
        this.$emit('submit', this.multiplePicked)
      } else {
        rsv(this.singleValue)
        this.$emit('submit', this.singleValue)
      }
      this.showVisible = false
    },
    handleClose () {
      rej('cancel')
      this.showVisible = false
    },
    pick () {
      return new Promise((resolve, reject) => {
        rsv = resolve
        rej = reject
        this.showVisible = true
      })
    }
  },
  computed: {
    visibleFriends () {
      return this.$store.state.friendStore.friends.filter(f => f.id !== 0 && this.visibleFunc(f)).map(m => ({
        ...m,
        disabled: this.disabledFunc(m)
      }))
    }
  }
}
</script>
